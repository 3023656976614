import { RecoilRoot } from "recoil";
import AppBootstrap from "./AppBootstrap";
import AuthProvider from "./shared/providers/AuthProvider";
import { UserProvider } from "./shared/providers/userProvider";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://93335e81fb3db2e9f901865470c3dc00@o4506384235495424.ingest.us.sentry.io/4508168009220096",
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Update 'tracePropagationTargets' to include both QA and production URLs
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/qa\.willowed\.org\/api/,
    /^https:\/\/app\.willowed\.org\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: process.env.REACT_APP_ENV === "production" ? 0.1 : 0,
  replaysOnErrorSampleRate: process.env.REACT_APP_ENV === "production" ? 1.0 : 0,
  environment: process.env.REACT_APP_ENV || "development",
});

function FallbackComponent() {
  return (
    <div className="error-boundary-fallback">
      <h1>Something went wrong</h1>
      <p>We've been notified and are looking into the issue.</p>
    </div>
  );
}

function App() {
  console.log("REACT_APP_TEST_VALUE", process.env.REACT_APP_TEST_VALUE);
  console.error("REACT_APP_TEST_VALUE", process.env.REACT_APP_TEST_VALUE);
  return (
    <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
      <RecoilRoot>
        <AuthProvider>
          <UserProvider>
            <AppBootstrap />
          </UserProvider>
        </AuthProvider>
      </RecoilRoot>
    </Sentry.ErrorBoundary>
  );
}

export default App;
